import React, { useEffect, useRef, useState } from "react";
import Image from "components/shared/Image";
import LeftArrow from "assets/images/left-arrow.svg";
import RightArrow from "assets/images/right-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { RESPONSE_DATA, ROLES } from "redux/constants";
import "./MonthRangePicker.scss";

const {
    SET_FILTERS
} = RESPONSE_DATA;

export const MonthRangePicker = ({
    startMonth,
    endMonth,
    initialYear = 2020,
    locale = 'en-US',
    calendarClassName,
    headerClassName,
    monthClassName,
    enabledMonths = [],
    monthOfInterviewRangeValue = '',
    type
}) => {
    const dispatch = useDispatch();
    const [yearOffset, setYearOffset] = useState(0);

    const renderDate = type === "start-month-picker" ? startMonth : endMonth;

    const displayYear = renderDate === null ? new Date().getFullYear() : renderDate.getFullYear() - yearOffset;
    const today = new Date();

    const currentYear = today.getFullYear()
    //debugger;
    const isLastYear = currentYear + yearOffset + 1 > currentYear;
    const isFirstYear = today.getFullYear() + (yearOffset - 1) < initialYear;

    // const isLastYear = currentYear + yearOffset + 1 - 1 > currentYear;
    // const isFirstYear = today.getFullYear() + (yearOffset - 1 - 1) < initialYear;

    const isMonthSelected = (month) => {
        if (!startMonth || !endMonth) {
            return false
        }
        const startYearMonth = startMonth.getFullYear() * 12 + startMonth.getMonth()
        const endYearMonth = endMonth.getFullYear() * 12 + endMonth.getMonth()
        const currentYearMonth = month.getFullYear() * 12 + month.getMonth()

        return (
            currentYearMonth >= startYearMonth && currentYearMonth <= endYearMonth
        )
    }

    const isMonthStart = (month) => {
        return month.getTime() === (startMonth?.getTime() || 0)
    }

    const isMonthEnd = (month) => {
        if (!endMonth) {
            return false
        }

        const endYearMonth = endMonth.getFullYear() * 12 + endMonth.getMonth()
        const currentYearMonth = month.getFullYear() * 12 + month.getMonth()

        return currentYearMonth === endYearMonth
    }

    const isDisabledMonth = (month) => {
        const startMonth = new Date(monthOfInterviewRangeValue.split(" - ")[0]).getTime();
        const endMonth = new Date(monthOfInterviewRangeValue.split(" - ")[1]).getTime();

        if (month.getTime() >= startMonth && month.getTime() <= endMonth) {
            return false;
        } else {
            return true;
        }
    }

    const handleMonthClick = (month) => {
        if (type === 'end-month-picker') {
            dispatch({
                type: SET_FILTERS, payload: {
                    response: {
                        monthOfInterviewRange: {
                            startMonth: startMonth,
                            endMonth: month
                        }
                    }
                }
            });
        }
        else {
            dispatch({
                type: SET_FILTERS, payload: {
                    response: {
                        monthOfInterviewRange: {
                            startMonth: month,
                            endMonth: endMonth
                        }
                    }
                }
            });
        }
        // if (!startMonth || endMonth) {
        //     dispatch({
        //         type: SET_FILTERS, payload: {
        //             response: {
        //                 monthOfInterviewRange: {
        //                     startMonth: month,
        //                     endMonth: null
        //                 }
        //             }
        //         }
        //     });
        // } else if (month < startMonth) {
        //     dispatch({
        //         type: SET_FILTERS, payload: {
        //             response: {
        //                 monthOfInterviewRange: {
        //                     startMonth: month,
        //                     endMonth: startMonth
        //                 }
        //             }
        //         }
        //     });
        // } else {
        //     dispatch({
        //         type: SET_FILTERS, payload: {
        //             response: {
        //                 monthOfInterviewRange: {
        //                     startMonth: startMonth,
        //                     endMonth: month
        //                 }
        //             }
        //         }
        //     });
        // }
    }

    const renderMonth = (year, month) => {
        const monthStart = new Date(year, month, 1)
        const isSelected = isMonthSelected(monthStart);
        const isStart = type === 'start-month-picker' && isMonthStart(monthStart);
        const isEnd = type === 'end-month-picker' && isMonthEnd(monthStart);
        let isDisabled = monthStart > today;

        const getVariants = () => {
            if (isStart) return 'start'
            if (isEnd) return 'end'
            // if (isSelected) return 'selected'
        }

        return (
            <div
                key={month}
                className={`${monthClassName} ${isDisabled ? 'disabled' : getVariants()}`}
                onClick={isDisabled ? undefined : () => handleMonthClick(monthStart)}
            >
                {new Intl.DateTimeFormat(locale, { month: 'short' }).format(monthStart)}
            </div>
        )
    }

    const renderYear = (year) => (
        <div className="year-container">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => renderMonth(year, month))}
        </div>
    )

    const handlePrevYear = () => {
        const offset = yearOffset - 1;

        if (isFirstYear) {
            return
        }

        setYearOffset(offset)
    }

    const handleNextYear = () => {
        if (!isLastYear) {
            const offset = yearOffset + 1

            setYearOffset(offset)
        }
    }
    return (
        <div className="month-range-picker">
            <div className={calendarClassName}>
                <div className={headerClassName}>
                    <div className={`icons prev-icon ${isFirstYear && 'disabled'}`} onClick={handlePrevYear}>
                        <div> <Image src={LeftArrow} alt="logo" /></div>
                    </div>
                    <div>
                        <div className="year-heading">
                            {today.getFullYear() + yearOffset}
                            {/* {2023 + yearOffset} */}
                        </div>
                    </div>
                    <div className={`icons next-icon ${isLastYear && 'disabled'}`} onClick={handleNextYear}>
                        <div><Image src={RightArrow} alt="logo" /></div>
                    </div>
                </div>
                <div>
                    {renderYear(today.getFullYear() + yearOffset)}
                    {/*renderYear(2023 + yearOffset) */}
                </div>
            </div>
        </div>
    );
};
