import React, { useEffect, useRef, useState } from "react";
import "./CompanyFilter.scss";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import SearchIcon from "assets/images/SearchIcon.svg";
import Image from "components/shared/Image";
import { RESPONSE_DATA, ROLES, TAXONOMY } from "redux/constants";
import { Checkbox } from '@kdshared/enrich-ui';
import { useDispatch, useSelector } from "react-redux";
import { isInViewport, scrollToTop } from "utils/helpers/helpers";

// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    SET_FILTERS,
    RETRIEVAL_API_RESULTS_SUCCESS,
    COMPANY_FILTER_APPLIED,
    CLEAR_TRANSCRIPT_SELECTED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
} = RESPONSE_DATA;

const {
    RESET_ALL_SL_PARENT_TAGS
} = TAXONOMY;

export const CompanyFilter = ({ showCompanyFilterContainer, handleFilterIconClick, companyList, lastUserQues }) => {

    const dispatch = useDispatch();

    const [searchCompany, setSearchCompany] = useState("");
    const filters = useSelector(responseSelector.getFilters);
    const queryResponse = useSelector(responseSelector.getRetrievalResult);
    const companyFilterApplied = useSelector(responseSelector.getCompanyFilterApplied);
    const monthOfInterviewRangeValue = useSelector(responseSelector.getMonthOfInterviewRangeValue);
    const industryFilterApplied = useSelector(responseSelector.getIndustryFilterApplied);
    const functionFilterApplied = useSelector(responseSelector.getFunctionFilterApplied);
    const transcriptResults = queryResponse ? [...queryResponse] : [];
    const groupedCompanies = filters.groupedCompanies || [];
    const sources = filters.sources || [];
    const filterData = filters.filteredIndustryTags || [];
    const filterFunctionTags = filters.filteredFunctionTags || [];
    const actionButtonContainerElementRef = useRef(null);
    let filterRef = useRef();
    const pageURL = window.location.href;

    useEffect(() => {
        if (showCompanyFilterContainer && actionButtonContainerElementRef.current) {
            const actionButtonContainer = actionButtonContainerElementRef.current;
            let windowScroll = isInViewport(actionButtonContainer);
            let existingScroll = window.scrollY;
            scrollToTop(existingScroll + windowScroll);
        }
    }, [showCompanyFilterContainer]);

    useEffect(() => {
        let filterHandler = (e) => {
            if (!filterRef.current.contains(e.target) && e.target.className !== 'filter company')
                handleFilterIconClick(false)
        }
        document.addEventListener("mousedown", filterHandler);

        return () => {
            document.removeEventListener("mousedown", filterHandler)
        }
    })

    useEffect(() => {
        if (searchCompany.length > 2) {
            const newGroupedCompanies = [...groupedCompanies];
            newGroupedCompanies.map(group => {
                group.children.map(status => {
                    const ifMatches = status.key.substring(0, searchCompany.length).toUpperCase() === searchCompany.toUpperCase()
                        && (companyFilterApplied > 0 ? companyList.indexOf(status.key.substring(0, status.key.lastIndexOf(" ("))) > -1 : true);
                    status.show = ifMatches;
                })
                group.show = group.children.filter(status => status.show).length > 0;
            })
            dispatch({ type: SET_FILTERS, payload: { newGroupedCompanies } });
        } else {
            const newGroupedCompanies = [...groupedCompanies];
            newGroupedCompanies.map(group => {
                group.filterShow = group.children.filter(status => status.filterShow).length > 0;
                group.children.map(status => {
                    if (companyList.length > 0 && companyFilterApplied > 0) {
                        const ifMatches = companyList.indexOf(status.key.substring(0, status.key.lastIndexOf(" ("))) > -1;
                        status.show = ifMatches;
                    } else {
                        status.show = true;
                    }
                    group.show = group.children.filter(status => status.show).length > 0;
                })
            })
            dispatch({ type: SET_FILTERS, payload: { newGroupedCompanies } });
        }
    }, [searchCompany]);

    const handleClearClick = (e) => {
        const newGroupedCompanies = [...groupedCompanies];
        newGroupedCompanies.map(group => {
            group.children.map(status => {
                status.selected = false
            })
        })
        dispatch({ type: SET_FILTERS, payload: { newGroupedCompanies } });
        handleApplyClick();
    }
    const handleApplyClick = (e) => {

        const companyList = [];
        groupedCompanies.map(group => {
            group.children.map(status => {
                if (status.selected) {
                    companyList.push(status.key);
                }
            })
        })
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Keyword_Search,
                    previousPageName: PAGES.Transcript_Library_Keyword_Search,
                    pageURL: pageURL
                }
            },
            internal: {
                search: { term: lastUserQues }
            }
        };
        newAdobeData.filterCategory = "Company Name";
        newAdobeData.filterChecked = companyList.join(" , ");


        ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.FILTER_CLICKS);

        handleFilterIconClick(false);
        //dispatch({ type: QUERY_RESPONSE, payload: { question: latestSearchText, filters, dispatch } });

        transcriptResults.forEach((transcript) => {
            const expert_employment_history = transcript.expert_employment_history;
            if (companyList.length > 0) {
                if (expert_employment_history && expert_employment_history.length > 0 && expert_employment_history.filter(eeh => companyList.indexOf(eeh.company) !== -1).length > 0) {
                    transcript.isHideCF = false;
                    return;
                } else {
                    transcript.isHideCF = true;
                }
            } else {
                transcript.isHideCF = false;
            }
        })

        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: transcriptResults } });
        dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: companyList.length } });
        dispatch({ type: RESET_ALL_SL_PARENT_TAGS, payload: { response: "" } });
    }
    const handleStatusClick = (event, data) => {
        const checkboxChecked = data.checked;
        const newGroupedCompanies = [...groupedCompanies];
        newGroupedCompanies.map(group => {
            group.children.map(status => {
                if (status.key === data.value)
                    status.selected = checkboxChecked
            })
        })
        dispatch({ type: SET_FILTERS, payload: { newGroupedCompanies } });
    };

    return (
        <div ref={filterRef} className="company-filter-container">
            <div className='filter-container'>
                <div className='filter-search-container'>
                    <div className="filter-search-box">
                        <Image src={SearchIcon} alt="logo" />
                        <input value={searchCompany} onChange={(e) => setSearchCompany(e.target.value)} placeholder="Search Company" />
                    </div>
                </div>
                <div className='filter-list-container'>
                    {groupedCompanies.map((group, index1) => (
                        <div key={index1}>
                            {group.show && group.filterShow &&
                                <div className="grouped-company"><span>{group.group}</span></div>}
                            {
                                group.children.map((company, index2) => (
                                    <div key={index1 + " " + index2}>
                                        {
                                            company.show && company.filterShow && <div className="filter-item">
                                                <Checkbox className="filter-checkbox" label={company.value} value={company.key} checked={company.selected} onClick={handleStatusClick} />
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))}
                </div>
                <div className="filter-separator"></div>
                <div className="filter-action-item" ref={actionButtonContainerElementRef}>
                    <button className="btn secondary button" onClick={handleClearClick}>Clear</button>
                    <button className="btn primary button" onClick={handleApplyClick}>Apply Now</button>
                </div>
            </div>
        </div >

    )
};